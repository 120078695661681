import React from 'react'
import PropTypes from 'prop-types'

import SEO from '~components/SEO'
import Header from '~components/Header'
import Hero from '~components/Hero'
import { heroPropTypes } from '~components/Hero/propTypes'
import { pictureDataPropTypes } from '~components/Picture/propTypes'

import Main from './Main'

const Layout = ({ children, pathname, hero, metaTags }) => (
  <>
    <SEO metaTags={metaTags} />
    <div>
      <Header pathname={pathname} />
      {hero && <Hero pictureData={hero.pictureData} textData={hero.textData} />}
      <Main>{children}</Main>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node,
  hero: PropTypes.shape(heroPropTypes),
  metaTags: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }),
  pictureData: pictureDataPropTypes,
}

export default Layout
