import React from 'react'
import Helmet from 'react-helmet'

import config from '~utils/siteConfig'
import { META_TAGS, CLOUDINARY_BASE_URL } from '~utils/constants'

const SEO = ({
  metaTags: {
    title: pageSpecificTitle,
    description,
    slug,
    image: { url: imageUrl, alt: imageAlt },
    publishDateISO,
    blogPostSEO,
  },
}) => {
  const title = `${pageSpecificTitle} | Tania Ferreira `

  const urlPage = `${config.siteUrl}${slug}/`
  const urlImage = `${CLOUDINARY_BASE_URL}/${imageUrl}.jpg`
  const imgWidth = config.shareImageWidth
  const imgHeight = config.shareImageHeight

  const twitterImageUrl = `${META_TAGS.twitterImagePrefix}/${imageUrl}.jpg`
  const openGraphImageUrl = `${META_TAGS.openGraphImagePrefix}/${imageUrl}.jpg`

  // Default Website Schema
  const schemaOrgJSONLD = [
    {
      '~context': 'http://schema.org',
      '~type': 'WebSite',
      'url': config.siteUrl,
      'name': config.siteTitle,
      'alternateName': config.siteTitleAlt,
    },
  ]

  // Blog Post Schema
  if (blogPostSEO) {
    schemaOrgJSONLD.push(
      {
        '~context': 'http://schema.org',
        '~type': 'BreadcrumbList',
        'itemListElement': [
          {
            '~type': 'ListItem',
            'position': 1,
            'item': {
              '~id': config.siteUrl,
              'name': config.siteTitle,
            },
          },
          {
            '~type': 'ListItem',
            'position': 2,
            'item': {
              '~id': urlPage,
              'name': title,
            },
          },
        ],
      },
      {
        '~context': 'http://schema.org',
        '~type': 'BlogPosting',
        'url': urlPage,
        'name': title,
        'alternateName': config.siteTitleAlt,
        'headline': title,
        'image': {
          '~type': 'ImageObject',
          'url': urlImage,
          'width': imgWidth,
          'height': imgHeight,
        },
        'author': {
          '~type': 'Person',
          'name': config.author,
          'url': config.authorUrl,
        },
        'publisher': {
          '~type': 'Organization',
          'name': config.publisher,
          'url': config.siteUrl,
        },
        'datePublished': publishDateISO || '',
        'mainEntityOfPage': urlPage,
      },
    )
    // Default Page Schema
  } else {
    schemaOrgJSONLD.push({
      '~context': 'http://schema.org',
      '~type': 'WebPage',
      'url': urlPage,
      'name': title,
    })
  }

  return (
    <Helmet>
      {/* General tags */}
      <html lang="de" />
      <title>{title}</title>
      <meta name="image" content={urlImage} />
      <meta name="description" content={description} />
      <meta name="referrer" content="origin" />
      <meta name="mobile-web-app-capable" content="yes" />

      {/* Windows */}
      <meta name="msapplication-config" content="/browserconfig.xml" />

      {/* Apple */}
      <link
        rel="mask-icon"
        href="/logos/safari-pinned-tab.svg?v=kP3Mzdp9r0"
        color="#2D4262"
      />
      <meta name="apple-mobile-web-app-title" content={config.siteTitle} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:title" itemProp="name" content={title} />
      <meta property="og:site_name" content={config.siteTitle} />
      {
        <meta
          property="og:type"
          content={blogPostSEO ? 'article' : 'website'}
        />
      }
      <meta property="og:url" content={urlPage} />
      <meta property="og:image" itemProp="image" content={openGraphImageUrl} />
      <meta property="og:image:width" content={imgWidth} />
      <meta property="og:image:height" content={imgHeight} />
      <meta
        property="og:description"
        itemProp="description"
        content={description}
      />
      <meta property="og:locale" content="de_DE" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta name="twitter:image:src" content={twitterImageUrl} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

export default SEO
