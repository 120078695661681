import PropTypes from 'prop-types'

const imageDataPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}).isRequired

export const pictureDataPropTypes = PropTypes.shape({
  path: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  settings: PropTypes.string,
  fallback: imageDataPropTypes,
  breakpoints: PropTypes.objectOf(imageDataPropTypes).isRequired,
})
