import styled from 'styled-components'

import { fluidSpacingFunc } from '~styles/helpers'
import { media } from '~styles/mediaQuery'

export default styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: ${({ theme }) => `0 ${theme.spacing[16]}`};
  ${fluidSpacingFunc([{ property: 'padding', values: [1.5, 3] }])};
  ${media.mq1`
    border: ${({ theme }) =>
      `${theme.spacing[2]} solid ${theme.palette.common.white}`};
  `};
  ${media.mq234`
    border: ${({ theme }) =>
      `${theme.spacing[4]} solid ${theme.palette.common.white}`};
  `};
`
