/**
 *
 * Picture
 *
 * Renders an picture, enforcing the usage of the alt="" tag
 */

import React, { useEffect, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { breakpoint } from '~styles/mediaQuery'
import { stringifySrcSet } from '~utils/helpers'
import { ROUNDING, MAX_PIXELDENSITY_ARRAY } from '~utils/constants'
import { maxWidthStyle } from '~components/MaxWidthWrapper'

import { pictureDataPropTypes } from './propTypes'

const picturesFallback = (
  { settings = 'f_auto,q_80', path, fallback },
  height,
) =>
  MAX_PIXELDENSITY_ARRAY.map(
    (pixelDensity) =>
      // +1 since array starts from 0, pixel density from 1
      `https://res.cloudinary.com/taniaferreira/image/upload/c_scale,dpr_${
        pixelDensity + 1
      }.0,${settings},h_${height}/${path}/${fallback.name}.jpg`,
  )

const picturesBreakpoints = (
  { settings = 'f_auto,q_80', path, breakpoints },
  height,
) =>
  Object.entries(breakpoints).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: MAX_PIXELDENSITY_ARRAY.map(
        (pixelDensity) =>
          // +1 since array starts from 0, pixel density from 1
          `https://res.cloudinary.com/taniaferreira/image/upload/c_scale,dpr_${
            pixelDensity + 1
          }.0,${settings},h_${height}/${path}/${value.name}.jpg`,
      ),
    }),
    {},
  )

const initialState = { placeholderHeight: 0, isLoaded: false }

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDimensions':
      return {
        ...state,
        placeholderHeight:
          Math.ceil(action.ref.current.scrollHeight / ROUNDING) * ROUNDING,
      }
    case 'setIsLoaded':
      return { ...state, isLoaded: true }
    default:
      throw new Error()
  }
}

const Picture = ({ className, pictureData, limit }) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const refPlaceholder = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'setDimensions', ref: refPlaceholder })
  }, [refPlaceholder])

  return (
    <Wrapper limit={limit} className={className} ref={ref}>
      <picture>
        {Object.entries(pictureData.breakpoints).map(([key, value]) => (
          <source
            key={key}
            media={`(max-width: ${breakpoint[key]}px)`}
            srcSet={value.placeholder}
          />
        ))}
        <Placeholder
          src={pictureData.fallback.placeholder}
          alt={pictureData.alt}
          isLoaded={state.isLoaded}
          // needed for page navigation (since on componentDidMount === 0)
          onLoad={() =>
            dispatch({ type: 'setDimensions', ref: refPlaceholder })
          }
          ref={refPlaceholder}
        />
      </picture>

      {inView && (
        <picture>
          {Object.keys(
            picturesBreakpoints(pictureData, state.placeholderHeight),
          ).map((picture) => (
            <source
              key={picture}
              media={`(max-width: ${breakpoint[picture]}px)`}
              srcSet={stringifySrcSet(
                picturesBreakpoints(pictureData, state.placeholderHeight)[
                  picture
                ],
              )}
            />
          ))}
          <Image
            srcSet={stringifySrcSet(
              picturesFallback(pictureData, state.placeholderHeight),
            )}
            src={picturesFallback(pictureData, state.placeholderHeight)[0]}
            alt={pictureData.alt}
            onLoad={() => dispatch({ type: 'setIsLoaded' })}
            isLoaded={state.isLoaded}
            crossOrigin="anonymous"
          />
        </picture>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  ${(props) => props.limit && maxWidthStyle};
`

export const Placeholder = styled.img`
  display: block;
  filter: ${(props) => (props.isLoaded ? 'blur(0px)' : 'blur(12px)')};
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.isLoaded ? 1 : 0)};
`

Picture.propTypes = {
  pictureData: pictureDataPropTypes.isRequired,
  limit: PropTypes.bool,
  className: PropTypes.string,
}

export default Picture
