import React from 'react'

import HeroTextWrapper from './HeroTextWrapper'
import HeroTextContent from './HeroTextContent'
import StyledHeroHeader from './StyledHeroHeader'
import StyledHeroSubHeader from './StyledHeroSubHeader'
import { heroTextDataPropTypes } from './propTypes'

const HeroText = ({ header, subHeader, styles }) => (
  <HeroTextWrapper styles={styles}>
    <HeroTextContent>
      {header && (
        <StyledHeroHeader as="h1" type="header1">
          {header}
        </StyledHeroHeader>
      )}
      {subHeader && (
        <StyledHeroSubHeader as="h2" type="header2">
          {subHeader}
        </StyledHeroSubHeader>
      )}
    </HeroTextContent>
  </HeroTextWrapper>
)

HeroText.propTypes = heroTextDataPropTypes

export default HeroText
