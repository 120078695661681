import styled from 'styled-components'

import Typography from '~components/Typography'

const StyledHeroHeader = styled(Typography)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
`

export default StyledHeroHeader
