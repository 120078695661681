import React, { Fragment } from 'react'

import {
  CLOUDINARY_BASE_URL,
  CLOUDINARY_DEFAULT_PARAMETER,
} from '~utils/constants'

export const stringifySrcSet = (images) =>
  images.reduce(
    (acc, cur, index) => `${acc ? `${acc}, ` : ''}${cur} ${index + 1}x`,
    '',
  )

export const getGatsbyImageSrcSet = ({ orgSrcSet, id, format }) => {
  const re = /[ ,]/
  const sizes = orgSrcSet
    .split(re)
    .filter((_size, i) => i % 2)
    .map((size) => size.replace('w', ''))

  const srcMap = sizes.map((width) => ({
    width,
    url: `${CLOUDINARY_BASE_URL}/${CLOUDINARY_DEFAULT_PARAMETER},w_${width}/${id}.${format}`,
  }))

  const srcSet = srcMap.reduce((acc, { url, width }) => {
    return `${acc ? `${acc}, ` : ''}${url} ${width}w`
  }, '')

  return srcSet
}

export const removeLinebreak = (text) => text && text.replace('\\n', ' ')

export const removeWhiteSpace = (text) => text.replace(/\s/g, '')

export const toMultiline = (text) => text.split('\\n').map(toSpanAndBr)

export const replaceHttpWithHttps = (text) =>
  text.replace(/^http:\/\//i, 'https://')

const toSpanAndBr = (text) => (
  <Fragment key={text}>
    <span key={text}>{text}</span>
    <br />
  </Fragment>
)
