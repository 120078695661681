import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import Picture, { Placeholder } from '~components/Picture'

const StyledPicture = styled(Picture)`
  margin: 0 auto;
  display: block;
  position: relative;

  & > ${Placeholder} {
    ${media.mq1`
      padding-top: ${(3 / 2) * 100}%;
    `};
    ${media.mq2`
      padding-top: 100%;
    `};
    ${media.mq34`
      padding-top: ${(5 / 9) * 100}%;
    `};
  }
`

export default StyledPicture
