import styled from 'styled-components'

export default styled.div`
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  left: 50%;
  width: 100%;
  text-align: center;
`
