module.exports = {
  siteTitle: 'Tania Ferreira',
  siteTitleAlt: 'Personal Trainerin in Zürich | Tania Ferreira', // This allows an alternative site title for SEO schema.
  publisher: 'Tania Ferreira', // Organization name used for SEO schema
  siteDescription: 'Personal Trainerin in Zürich | Tania Ferreira',
  siteUrl: 'https://tania-ferreira.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 12, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 16, // Number of posts shown on paginated pages
  author: 'Tania Ferreira', // Author for RSS author segment and SEO schema
  authorUrl: 'https://tania-ferreira.com/ueber-mich/', // URL used for author and publisher schema, can be a social profile or other personal site
  shortTitle: 'Tania Ferreira', // Used for App manifest e.g. Mobile Home Screen
  shareImageWidth: 1197, // Change to the width of your default share image
  shareImageHeight: 630, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#FAFAFA', // Used for Offline Manifest
  themeColor: '#F2BFAE', // Used for Offline Manifest
  copyright: 'Copyright © 2019 Tania Ferreira', // Copyright string for the RSS feed
  cloudinaryRawUrl: 'https://res.cloudinary.com/taniaferreira/raw/upload',
  randomCloudinaryVersionNumber: Math.floor(Math.random() * 1000000000),
}
