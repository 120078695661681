import PropTypes from 'prop-types'

import { pictureDataPropTypes } from '~components/Picture/propTypes'

export const heroTextDataPropTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  styles: PropTypes.object,
}

export const heroPropTypes = {
  pictureData: pictureDataPropTypes.isRequired,
  textData: PropTypes.shape(heroTextDataPropTypes).isRequired,
}
