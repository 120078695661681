import React from 'react'

import Wrapper from './Wrapper'
import HeroText from './HeroText'
import { heroPropTypes } from './propTypes'
import StyledPicture from './StyledPicture'

const Hero = ({ pictureData, textData }) => (
  <Wrapper>
    <StyledPicture pictureData={pictureData} />
    <HeroText {...textData} />
  </Wrapper>
)

Hero.propTypes = heroPropTypes

export default Hero
